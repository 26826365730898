"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/web.dom.iterable");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _editInBoundForm = _interopRequireDefault(require("./dialog/editInBoundForm"));
var _editAPayPwd = _interopRequireDefault(require("./dialog/editAPayPwd.vue"));
var _merchant = require("@/api/merchant");
var _inOutBound = require("@/api/inOutBound");
var _auth = require("@/utils/auth");
var _MerchantFilter = _interopRequireDefault(require("./components/MerchantFilter.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    editInBoundForm: _editInBoundForm.default,
    editAPayPwd: _editAPayPwd.default,
    MerchantFilter: _MerchantFilter.default
  },
  data: function data() {
    return {
      searchForm: {
        pay_no: '',
        account: '',
        status: '',
        vehicle_id: '',
        name: '',
        purchase_price: '',
        chassis_number: '',
        license_plate: '',
        remark: '',
        is_registered: '',
        merchantId: 0,
        createTime: ''
      },
      createTime: [],
      merchantId: 0,
      tableData: [],
      total: 0,
      selectList: [],
      selectedItem: {},
      tableLoading: false,
      inBoundInfo: {
        total_num: undefined,
        total_amount: undefined
      },
      userType: 0
    };
  },
  computed: {
    // totalAmount
    totalAmount: function totalAmount() {
      var total = 0;
      this.tableData.forEach(function (item) {
        total += item.purchase_price;
      });
      return total;
    }
  },
  methods: {
    initCreateTime: function initCreateTime() {
      return;
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      var day = today.getDate();
      var startOfDay = "".concat(year, "-").concat(month.toString().padStart(2, '0'), "-").concat(day.toString().padStart(2, '0'), " 00:00:00");
      var endOfDay = "".concat(year, "-").concat(month.toString().padStart(2, '0'), "-").concat(day.toString().padStart(2, '0'), " 23:59:59");
      this.createTime = [startOfDay, endOfDay];
    },
    handleMerchantSelected: function handleMerchantSelected(val) {
      console.log('handleMerchantSelected', val);
      this.searchForm.merchantId = val;
    },
    // 删除
    removeItem: function removeItem(id) {
      var _this = this;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _inOutBound.delInBound)({
          id: id
        }).then(function (res) {
          _this.getList(true);
        });
      }).catch(function () {});
    },
    // 获取数据
    getList: function getList() {
      var _this2 = this;
      this.selectedItem = {};
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _merchant.getMerchantList)({}).then(function (res) {
        console.log('res: ', res);
      });
      this.tableLoading = true;
      console.log('searchForm', this.searchForm);
      (0, _inOutBound.getInBoundList)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this2.tableData = res.data.data || [];
        _this2.inBoundInfo = res.inBoundInfo;
        console.log('this.inBoundInfo', _this2.inBoundInfo);
        _this2.total = res.data.total || 0;
        console.log('table data: ', _this2.tableData);
      }).finally(function () {
        _this2.tableLoading = false;
      });
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
        pay_no: '',
        name: '',
        account: '',
        status: ''
      }, "name", ''), "purchase_price", ''), "chassis_number", ''), "license_plate", ''), "is_registered", ''), "createTime", '');
      this.initCreateTime();
      if (this.userType == 0) {
        this.$refs.merchantFilterRef.reset();
      }
      this.getList();
    },
    // 导出
    exportFile: function exportFile() {
      leaseListExport(this.searchForm);
    },
    addAPaymentData: function addAPaymentData() {
      this.selectedItem = {};
      this.$refs.editInBoundForm.dialogVisible = true;
    },
    saveAPaymentData: function saveAPaymentData(item) {
      this.selectedItem = item;
      this.$refs.editInBoundForm.dialogVisible = true;
    },
    handlePayPwd: function handlePayPwd(id) {
      this.selectedItem = id;
      console.log('selectedItem', this.selectedItem);
      this.$refs.editAPayPwd.dialogVisible = true;
    },
    // 供应商可筛选商户
    suplierFilter: function suplierFilter() {
      var merchantId = (0, _auth.getMerchantId)();
      this.userType = merchantId == 0 ? 0 : 1; // 如果商户ID为0，则表示供应商，否则表示商户
    }
  },
  watch: {
    createTime: function createTime(val) {
      if (val) {
        this.searchForm.createTime = val.join('~');
      } else {
        this.searchForm.createTime = '';
      }
    }
  },
  mounted: function mounted() {
    console.log('query', this.$route.query);
    if (this.$route.query.chassis_number) {
      this.searchForm.chassis_number = this.$route.query.chassis_number;
    }
    console.log('this.searchForm.chassis_number', this.searchForm.chassis_number);
    this.initCreateTime();
    this.suplierFilter();
    this.getList();
  }
};