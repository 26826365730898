"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _finance = require("@/api/finance");
var _deductLog = _interopRequireDefault(require("@/components/dialogs/deductLog"));
var _setWithhold = _interopRequireDefault(require("./setWithhold"));
var _changeLease = _interopRequireDefault(require("./changeLease"));
var _withholdDeposit = _interopRequireDefault(require("./withholdDeposit"));
var _leaseRepayment = _interopRequireDefault(require("./leaseRepayment"));
var _qrCodeRepayment = _interopRequireDefault(require("./qrCodeRepayment"));
var _setSplit = _interopRequireDefault(require("./setSplit"));
var _mayi = require("@/api/mayi");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['orderId', 'orderStatus', 'editItem', 'merchantId'],
  components: {
    deductLog: _deductLog.default,
    setWithhold: _setWithhold.default,
    withholdDeposit: _withholdDeposit.default,
    leaseRepayment: _leaseRepayment.default,
    setSplit: _setSplit.default,
    changeLease: _changeLease.default,
    qrCodeRepayment: _qrCodeRepayment.default
  },
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      isFullscreen: false,
      isTableFault: false,
      // 是否存在异常订单
      tableData: [],
      selectedItem: {},
      selectedId: 0,
      searchForm: {
        leaseStatus: ''
      },
      selectList: [{
        id: -1,
        name: '全部'
      }, {
        id: 1,
        name: '已支付'
      }, {
        id: 2,
        name: '未支付'
      }],
      allSuccessAmount: 0,
      allErrorAmount: 0,
      withHoldCount: {
        total: 0,
        success: 0
      } //订单代扣信息
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.isFullscreen = false;
        this.getLease();
      }
    }
  },
  methods: {
    getLease: function getLease() {
      var _this = this;
      this.loading = true;
      // this.tableData = []
      (0, _order.getLease)({
        orderId: this.orderId,
        status: this.searchForm.leaseStatus
      }).then(function (res) {
        _this.tableData = res.data || [];
        _this.allSuccessAmount = parseFloat(res.allSuccessAmount).toFixed(2);
        _this.allErrorAmount = parseFloat(res.allErrorAmount).toFixed(2);
        if (res.withHoldCount) _this.withHoldCount = res.withHoldCount;
        _this.loading = false;
        _this.chkTableData();
      });
    },
    chkTableData: function chkTableData() {
      var _this2 = this;
      this.isTableFault = false;
      if (this.tableData.length > 0) {
        this.tableData.forEach(function (item) {
          if (!_this2.isTableFault && [4, 8].indexOf(item.status) > -1) {
            console.log('chkTableData', [4, 8].indexOf(item.status));
            _this2.isTableFault = true;
          }
        });
      }
    },
    getTagLabel: function getTagLabel(status) {
      var label = '';
      switch (status) {
        case 1:
          label = '已支付';
          break;
        case 2:
          label = '已退款';
          break;
        case 3:
          label = '已买断';
          break;
        case 4:
          label = '已逾期';
          break;
        case 5:
          label = '线下支付';
          break;
        case 6:
          label = '审核中';
          break;
        case 7:
          label = '审核被拒';
          break;
        case 8:
          label = '代扣失败';
          break;
        default:
          label = '未支付';
      }
      return label;
    },
    getTagType: function getTagType(status) {
      var type = 'info';
      if ([1, 5].indexOf(status) > -1) {
        type = 'success';
      } else if ([6].indexOf(status) > -1) {
        type = 'warning';
      } else if ([4, 7, 8].indexOf(status) > -1) {
        type = 'danger';
      } else if ([2, 3].indexOf(status) > -1) {
        type = '';
      }
      return type;
    },
    switchChange: function switchChange(row) {
      var _this3 = this;
      (0, _finance.withholdSwitch)({
        orderId: row.order_id,
        leaseId: row.id,
        withholdStatus: row.withhold_status
      }).then(function (res) {
        _this3.$parent.getList(true);
      });
    },
    viewLog: function viewLog(row) {
      this.selectedItem = _objectSpread({}, row);
      this.$refs.deductLog.dialogVisible = true;
    },
    // 申请扣款
    openWithhold: function openWithhold(row) {
      // if (this.isTableFault && [4, 8].indexOf(row.status) == -1) {
      //     this.$func.isError('存在扣款失败订单,请优先处理失败订单')
      //     return
      // }
      this.selectedItem = _objectSpread({}, row);
      this.$refs.setWithhold.dialogVisible = true;
      return;
      // this.$confirm("您确定要申请扣款吗？", "申请扣款", {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消'
      // }).then(() => {
      // 	applyWithhold({
      // 		order_id: row.order_id,
      // 		lease_id: row.id,
      // 	}).then(res => {
      // 		this.getLease()
      // 		setTimeout(() => {
      // 			this.$parent.getList(true);
      // 		}, 1000);
      // 	})
      // }).catch(() => {});
    },
    refresh: function refresh() {
      this.getLease();
      this.$parent.getList(true);
    },
    // 发起扣款
    withholdItem: function withholdItem(row) {
      var _this4 = this;
      this.$confirm('您确定要发起扣款吗？', '发起扣款', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _finance.agreeWithholding)({
          orderId: row.order_id,
          leaseId: row.id
        }).then(function (res) {
          _this4.refresh();
          setTimeout(function () {
            _this4.getLease();
          }, 1000 * 6);
        });
      }).catch(function () {});
    },
    // 线下还款
    payOffline: function payOffline(item) {
      this.selectedItem = _objectSpread({}, item);
      this.$refs.leaseRepayment.dialogVisible = true;
    },
    payOfflineQrCode: function payOfflineQrCode(item) {
      this.selectedItem = _objectSpread({}, item);
      // 电动车出库订单
      console.log('item.is_used', item.is_used, this.selectedId);
      if (item.is_used) {
        this.$refs.qrCodeRepayment.dialogVisible = true;
      }
    },
    paySplit: function paySplit(row) {
      var _this5 = this;
      this.$confirm('注意:拆分账单后，将影响本订单的征信相关功能，请谨慎操作!!!', '账单拆分', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        distinguishCancelAndClose: true
      }).then(function () {
        _this5.selectedItem = _objectSpread({}, row);
        _this5.$refs.setSplit.dialogVisible = true;
      }).catch(function () {});
    },
    // 金额校正
    resetAmount: function resetAmount(row) {
      var _this6 = this;
      this.$confirm('注意:此功能用于校正订单拆分后,支付宝返回代扣订单金额不一致的问题，请谨慎操作!!!', '金额校正', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        distinguishCancelAndClose: true
      }).then(function () {
        (0, _order.resetAmount)({
          order_id: row.order_id,
          lease_id: row.id
        }).then(function (res) {
          _this6.refresh();
        });
      });
    },
    clearWithholdCache: function clearWithholdCache(row) {
      var _this7 = this;
      this.$confirm('注意:该操作有可能会导致程序扣钱、代扣、用户支付同时进行，请先确认安全', '取消支付5分钟限制', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        distinguishCancelAndClose: true
      }).then(function () {
        (0, _order.clearWithholdCacheApi)({
          leaseId: row.id
        }).then(function (res) {
          _this7.refresh();
        });
      }).catch(function () {});
    },
    mayiOrderRentalOne: function mayiOrderRentalOne(row) {
      var _this8 = this;
      this.$confirm('您确定要上链租期信息吗？', '上链信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _mayi.maYiLeaseRentalCreateOne)({
          order_id: row.order_id,
          lease_id: row.id
        }).then(function (res) {
          _this8.refresh();
        });
      }).catch(function () {});
    },
    toggleFullscreen: function toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    },
    withholdDeposit: function withholdDeposit(order_id) {
      this.selectedId = order_id;
      this.$refs.withholdDeposit.dialogVisible = true;
    },
    changeLease: function changeLease(row) {
      this.selectedItem = _objectSpread({}, row);
      this.$refs.changeLease.dialogVisible = true;
    },
    changeRefund: function changeRefund(row) {
      var _this9 = this;
      this.$confirm('注意:此功能会改变支付状态，增加退款流水记录。退款需要另外手动操作，请谨慎操作!!!', '线下退款', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _finance.changeRefund)({
          order_id: row.order_id,
          lease_id: row.id
        }).then(function (res) {
          _this9.refresh();
        });
      }).catch(function () {});
    }
  }
};