"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _merchant = require("@/api/merchant");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      activeStep: 0,
      balance: 0,
      frozen: 0,
      form: {
        type: 1,
        user: '',
        amount: ''
      },
      infoData: {},
      isLoading: false
    };
  },
  props: ['withdrawId'],
  watch: {
    $route: function $route(val) {
      console.log(val);
      if (this.withdrawId) {
        this.getInfo();
      } else {
        this.getAccount();
      }
    }
  },
  mounted: function mounted() {
    this.getData();
    if (this.withdrawId) {
      this.getInfo();
    } else {
      this.getAccount();
    }
  },
  methods: {
    getData: function getData() {
      var _this = this;
      (0, _merchant.myAccount)().then(function (res) {
        var balance = res.balance,
          frozen = res.frozen;
        _this.balance = balance;
        _this.frozen = frozen;
      });
    },
    getAccount: function getAccount() {
      var _this2 = this;
      this.activeStep = 0;
      this.form = {
        type: 1,
        user: '',
        amount: ''
      };
      (0, _merchant.getPayeeInfo)().then(function (res) {
        var payeename = res.payeename,
          alipayaccount = res.alipayaccount;
        if (alipayaccount) {
          _this2.form.user = alipayaccount + '（' + payeename + '）';
        }
      });
    },
    getInfo: function getInfo() {
      var _this3 = this;
      (0, _merchant.withdrawalInfo)(this.withdrawId).then(function (res) {
        _this3.infoData = res && res.withdrawal_info ? res.withdrawal_info : {};
        console.log(_this3.infoData);
        _this3.activeStep = _this3.infoData.status == 2 ? 2 : 1;
      });
    },
    changeMoneyInput: function changeMoneyInput() {
      var pattern = /^[0-9]+([.]{1}[0-9]{1,2})?$/; // 正数的正则表达式
      // 不符合正整数时
      if (!pattern.test(this.form.amount)) {
        // input 框绑定的内容为空
        this.form.amount = '';
      }
    },
    submit: function submit() {
      var _this4 = this;
      console.log(this.isLoading);
      if (!this.form.user) {
        return;
      }
      if (this.form.amount <= 0) {
        this.$message({
          message: "提现金额不能为空，不能小于等于零",
          type: "warning"
        });
        return;
      }
      // const pos = String(this.form.amount).indexOf('.') + 1;
      // const num = String(this.form.amount).length - pos;
      // console.log(pos, num);
      // if (num > 2) {
      //   this.$message({
      //     message: "提现金额小数点后不能超过两位",
      //     type: "warning"
      //   })
      //   return;
      // }
      this.isLoading = true;
      (0, _merchant.withdrawalApply)(this.form.amount).then(function (res) {
        // this.$message({
        // 	message: "提现成功，请到提现记录查看",
        // 	type: "success"
        // })
        // this.$router.push({ name: 'merchantMyWithdraw', params: { withdrawId: res.withdrawal_id } });
      }).catch(function (error) {
        console.error(error);
      }).finally(function () {
        _this4.isLoading = false;
      });
    }
  }
};