"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
var _merchant = require("@/api/merchant");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _ipmanager = _interopRequireDefault(require("./ipmanager"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import addDialog from "./add"
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    ipmanagerVue: _ipmanager.default
    // addDialog
  },
  data: function data() {
    return {
      searchForm: {
        mobile: "",
        status: null
      },
      total: 0,
      tableData: [],
      editItem: {},
      roleList: []
    };
  },
  filters: {},
  mounted: function mounted() {
    this.getList();
    // this.getRoleList();
  },
  methods: {
    //管理IP
    handleIp: function handleIp() {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.editItem = item;
      this.editItem.titleName = "商户IP白名单设置";
      // console.log("this.edititem", this.editItem);
      this.$refs.ip.dialogVisible = true;
    },
    // 删除
    removeItem: function removeItem(id) {
      var _this = this;
      this.$confirm("您确定要删除吗?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(function () {
        (0, _system.delMerchant)(id).then(function (res) {
          _this.getList(true);
        });
      }).catch(function () {});
    },
    // 添加/编辑账号
    openAddDialog: function openAddDialog(val) {
      this.editItem = val || null;
      this.$refs.addDialog.dialogVisible = true;
    },
    // 批量操作
    handleCommand: function handleCommand(command) {
      var list = this.$refs.multipleTable.selection;
      if (list.length === 0) {
        this.$message({
          message: "没有勾选项",
          type: "warning"
        });
        return;
      }
      var ids = list.map(function (item) {
        return item.id;
      }).join(",");
      switch (command) {
        case "delete":
          this.removeItem(ids);
          break;
        case "enable":
          this.changeStatus(ids, 0);
          break;
        case "disable":
          this.changeStatus(ids, 1);
          break;
        default:
          break;
      }
    },
    // 添加/查看 商户
    viewMerchant: function viewMerchant() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      this.$router.push({
        name: "merchantAccountView",
        params: {
          viewId: id
        }
      });
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        mobile: "",
        status: null
      };
      this.getList();
    },
    // 启用/禁用
    changeStatus: function changeStatus(ids, status) {
      var _this2 = this;
      var tip = "",
        title = "";
      if (status == 1) {
        tip = "您确定要禁用吗？";
        title = "禁用";
      } else {
        tip = "您确定要启用吗？";
        title = "启用";
      }
      this.$confirm(tip, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(function () {
        (0, _system.setMerchant)({
          ids: ids,
          status: status == 1 ? 0 : 1
        }).then(function (res) {
          _this2.getList(true);
        });
      }).catch(function () {});
    },
    // 启用/禁用 商户转单
    changeTransStatus: function changeTransStatus(ids, status) {
      var _this3 = this;
      var tip = "",
        title = "";
      if (status == 1) {
        tip = "您确定要在转单中隐藏商户吗？";
        title = "隐藏";
      } else {
        tip = "您确定要显示商户转单吗？";
        title = "显示";
      }
      this.$confirm(tip, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(function () {
        (0, _system.setMerchant)({
          ids: ids,
          key: 'trans_status',
          status: status == 1 ? 2 : 1
        }).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    },
    // 启用/禁用 商户转单
    changeNewGoods: function changeNewGoods(ids, status) {
      var _this4 = this;
      var tip = "",
        title = "";
      if (status == 1) {
        tip = "您确认该商户接受二手机？";
        title = "全系";
      } else {
        tip = "您确认该商户只接受新机？";
        title = "新机";
      }
      this.$confirm(tip, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(function () {
        (0, _system.setMerchant)({
          ids: ids,
          key: 'new_goods',
          status: status == 1 ? 0 : 1
        }).then(function (res) {
          _this4.getList(true);
        });
      }).catch(function () {});
    },
    getList: function getList(flag) {
      var _this5 = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _merchant.getMerchantList)({
        page: page,
        pageSize: pageSize,
        status: this.searchForm.status === null ? -100 : this.searchForm.status,
        mobile: this.searchForm.mobile
      }).then(function (res) {
        _this5.tableData = res.data || [];
        _this5.total = res.total;
      });
    },
    getRoleList: function getRoleList() {
      var _this6 = this;
      (0, _system.getGroupList)().then(function (res) {
        _this6.roleList = res || [];
      });
    },
    loginMerchant: function loginMerchant(item) {
      var _this7 = this;
      (0, _merchant.getMerchantOnceToken)(item.id).then(function (res) {
        var url = "";
        if (res.domain_shop) {
          url = "https://" + res.domain_shop + "/login?token=".concat(res.token, "&type=1");
        } else {
          url = _this7.$globalObj.merchantUrl + "?token=".concat(res.token, "&type=1");
        }
        // console.log('url',url)
        _this7.$func.openWindow(url);
      });
    }
  }
};